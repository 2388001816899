<template>
    <tr>
      <td v-if="true" class="ultra-date-alerts">
        {{ alert.dateToLocal() }}
      </td>
      <td>
        {{propData.Lvl}}
      </td>
      <td>
        {{propData.Id}}
      </td>
      <td>
        {{propData['obj D-1']}}
      </td>
      <td>
        {{propData['spend D-1']}}
      </td>
      <td>
        {{propData['underdelivery D-1']}}
      </td>
      <td>
        {{propData['Consecutive days of underdelivery']}}
      </td>
      <td>
        {{propData['Weight in the IO']}}
      </td>
      <td>
        {{propData['underdelivery score']}}
      </td>
      <td>
        {{ propData['A_param_otto'] }}
      </td>
      <td>
        {{ propData['B_param_otto'] }}
      </td>
      <td>
        {{ propData['Delivery ok for Otto'] }}
      </td>
      <td>
        {{ propData['Otto limited by constraint'] }}
      </td>
    </tr>
</template>

<script>
import UltraDetailedMixin from './mixin/UltraDetailedMixin'

export default {
  mixins: [UltraDetailedMixin],
  name: 'UnderdeliveryDetailed',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {
  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {}
}
</script>

<style>
  .ultra-date-alerts {
    width: 4em;
  }
</style>
